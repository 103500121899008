// JS VENDOR FREE
import './vendor/free/bs-custom-file-input'
import './vendor/free/chart'
import './vendor/free/chartjs-datalabels'
import './vendor/free/enhanced-modals'
import './vendor/free/jquery.easing'
import './vendor/free/velocity'
import './vendor/free/waves'

// JS FREE
import './free/forms-free'
import './free/scrolling-navbar'
import './free/treeview'
import './free/wow'
